import { api, paths } from '@/api';
import { setUserToken, getPatchPayload } from '@/utils';
import { collectionStatusAlertTypes } from '@/constants';

const state = {
  client: {},
  usersToImpersonate: [],
};

const getters = {
  collectionStatus: state => state.client?.collectionStatus,
  collectionInstruction: state => collectionStatusAlertTypes.find(x => x.name === state.client?.collectionStatus),
};

const actions = {
  async getClient({ commit }, options) {
    const { referralId, agentId, name } = options;
    const pathByReferralType = {
      Property: paths.REFERRAL_PROPERTY,
      Buyer: paths.REFERRAL_BUYER_PROFILE,
    };

    const path = pathByReferralType[name];
    if (!path) return;
    const { data } = await api.get(path(agentId, referralId));
    commit('setClient', data);
    return data;
  },
  async getClientHistory(context, options) {
    const { profileId, agentId, clientType } = options;
    const { PROPERTY_HISTORY, BUYER_HISTORY } = paths;
    const historyPath = clientType === 'SELLER' ? PROPERTY_HISTORY : BUYER_HISTORY;
    const { data } = await api.get(historyPath(profileId, agentId), options);
    return data;
  },
  async getAttachments(context, options) {
    const { profileId, clientType } = options;
    const { PROPERTY_ATTACHMENTS, BUYER_ATTACHMENTS } = paths;
    const historyPath = clientType === 'SELLER' ? PROPERTY_ATTACHMENTS : BUYER_ATTACHMENTS;
    const { data } = await api.get(historyPath(profileId));
    return data;
  },
  async saveAlcf(context, payload) {
    await api.post(paths.ALCF_FORM, payload);
  },
  async saveIcf(context, payload) {
    await api.post(paths.ICF_FORM, payload);
  },
  async saveLaf(context, payload) {
    await api.post(paths.LAF_FORM, payload);
  },
  async savePcf(context, payload) {
    await api.post(paths.PCF_FORM, payload);
  },
  async fetchPcf(context, params) {
    const axiosOptions = { params };
    const { data } = await api.get(paths.PROPERTY_PCS_FIELDS, axiosOptions);
    return data;
  },
  async saveSpf(context, payload) {
    const {
      agentId, clientId, finalSoldDate, file,
    } = payload;
    const params = { finalSoldDate };
    const axiosOptions = { params };
    await api.post(paths.SPF_FORM(agentId, clientId), file, axiosOptions);
  },
  async saveBsf(context, payload) {
    const {
      agentId, clientId, finalClosingDate, file,
    } = payload;
    const params = { finalClosingDate };
    const axiosOptions = { params };
    await api.post(paths.BSF_FORM(agentId, clientId), file, axiosOptions);
  },
  async fetchBsf(context, buyerProfileId) {
    const { data } = await api.get(paths.BUYER_BSF_FIELDS(buyerProfileId));
    return data;
  },
  async fetchIospf(context, propertyId) {
    const { data } = await api.get(paths.PROPERTY_IOSPF_FIELDS(propertyId));
    return data;
  },
  async fetchSpf(context, propertyId) {
    const { data } = await api.get(paths.PROPERTY_SPF_FIELDS(propertyId));
    return data;
  },
  async contractFellThrough(context, payload) {
    const { params, pathName } = payload;
    const axiosOptions = { params };
    await api.post(paths[pathName], null, axiosOptions);
  },
  async getUsersToImpersonate({ commit }) {
    const { data } = await api.get(paths.GET_USERS_TO_IMPERSONATE, {
      tokenType: 'admin',
    });
    commit('setUsersToImpersonate', data);
    return data;
  },
  async getImpersonationToken(context, params) {
    const axiosOptions = {
      tokenType: 'admin',
      params,
    };
    const { data } = await api.post(paths.GET_IMPERSONATION_TOKEN, null, axiosOptions);
    setUserToken(data.token);
    return data;
  },
  async updateAssist(context, payload) {
    await api.post(paths.ASSIST_FORM, payload);
  },
  async saveBuf(context, payload) {
    await api.post(paths.BUF_FORM, payload);
  },
  async saveIbcf(context, payload) {
    await api.post(paths.IBCF_FORM, payload);
  },
  async fetchBuf(context, params) {
    const axiosOptions = { params };
    const { data } = await api.get(paths.BUYER_BUF_FIELDS, axiosOptions);
    return data;
  },
  async fetchPropertyQuestionnaireFields(context, propertyId) {
    const { data } = await api.get(paths.PROPERTY_CASH_OFFER(propertyId));
    return data.data;
  },
  async updatePropertyQuestionnaireFields(context, payload) {
    const { fieldsToUpdate, propertyId } = payload;
    const requestBody = getPatchPayload(fieldsToUpdate);
    await api.patch(
      paths.PROPERTY_CASH_OFFER(propertyId),
      requestBody,
    );
  },
  async fetchPropertyOfferFields(context, propertyId) {
    const { data } = await api.get(paths.PROPERTY_OFFER_FIELDS(propertyId));
    return data;
  },
  async fetchStatement(context, params) {
    let path = params.isSeller ? paths.GENERATE_SELLER_STATEMENT(params.id) : paths.GENERATE_BUYER_STATEMENT(params.id);
    path = `${path}?${params.queryString}`;
    const { data } = await api.get(path, {
      responseType: 'blob',
    });
    return data;
  },
  async fetchStatementCalculation(context, params) {
    let path = params.isSeller ? paths.CALCULATION_REFERRAL_FEE_SELLER(params.id) : paths.CALCULATION_REFERRAL_FEE_BUYER(params.id);
    path = `${path}?${params.queryString}`;
    const { data } = await api.get(path);
    return data;
  },
  async getStatementFileNameFromClient(context, client) {
    const lastName = client.lastName.replaceAll(' ', '_');
    const type = client.clientType === 'buyer' ? 'buyer' : 'property';
    return `${lastName}-statement-${type}.pdf`;
  },
};

const mutations = {
  setClient(state, client) {
    state.client = client;
  },
  setUsersToImpersonate(state, users) {
    state.usersToImpersonate = users;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
