import Vue from 'vue';
import {
  getUserToken,
  getAdminToken,
} from '@/utils';
import { pauseFeature, agentImageType, collectionStatusAlertTypes } from '@/constants';

import {
  api,
  paths,
} from '@/api';

const state = {
  user: {},
  admin: {},
  agent: {},
  summary: {},
  tours: [],
  currentClient: {},
  asa: null,
  secondaryAgent: null,
  secondaryAgents: [],
  assignedClients: [],
  loading: {
    global: false,
    asa: false,
    user: false,
    dashboard: true,
    details: false,
  },
  listingsOnDemand: [],
};

const showCollectionStatus = collectionStatusAlertTypes.map(x => x.name);

const getters = {
  clients(state) {
    if (!state.assignedClients) return [];
    return state.assignedClients;
  },
  unseenTours: state => state.tours.filter(tour => !tour.dtView),
  seenTours: state => state.tours.filter(tour => tour.dtView),
  isPausedBanner: state => pauseFeature.isActive && state.assignedClients.filter(x => x.needUpdate).length >= pauseFeature.countCeiling,
  secondaryAgentOptions: state => state.secondaryAgents.filter(a => a.type !== 'TEAM_LEAD').map(a => ({ value: a.id, text: a.fullName })),
  secondaryAgentName: state => ((id) => state.secondaryAgents.find(a => id === a.id)?.fullName),
  profileImageUrl: state => (state.agent?.profileImage?.id ? `https://${state.agent.profileImage.bucket}.s3.amazonaws.com/${state.agent.profileImage.storageName}` : null),
  brokerageLogoUrl: state => (state.agent?.brokerageLogo?.id ? `https://${state.agent.brokerageLogo.bucket}.s3.amazonaws.com/${state.agent.brokerageLogo.storageName}` : null),
  currentListingsOnDemand: state => state.listingsOnDemand,
  isInCollection: state => showCollectionStatus.includes(state.agent?.collectionStatus),
  collectionStatus: state => state.agent?.collectionStatus,
  collectionInstruction: state => collectionStatusAlertTypes.find(x => x.name === state.agent?.collectionStatus),
};

const actions = {
  async getUserDetails({ commit }, isAdmin) {
    const token = isAdmin ? getAdminToken() : getUserToken();
    const params = {
      token,
    };
    const axiosOptions = {
      params,
    };
    if (isAdmin) {
      axiosOptions.tokenType = 'admin';
    }
    if (!isAdmin) {
      commit('setLoading', { type: 'user', status: true });
    }
    const { data: { user, impersonatedUser } } = await api.get(paths.USER_DATA, axiosOptions);
    if (impersonatedUser) {
      commit('setUser', impersonatedUser);
      commit('setAdmin', user);
    } else if (!isAdmin) {
      commit('setUser', user);
    }
    if (!isAdmin) {
      commit('setLoading', { type: 'user', status: false });
    }
    return { user, impersonatedUser };
  },
  async getAgentDetails({ commit, state }) {
    const { agentId } = state.user;
    if (!agentId) return;
    commit('setLoading', { type: 'details', status: true });

    const { data: basicInfo } = await api.get(paths.AGENT_BASIC_INFO(agentId));
    const { data: assignedClients } = await api.get(paths.REFERRAL_BY_AGENT_ID(agentId));
    const { data: attachments } = await api.get(paths.AGENT_ATTACHMENT(agentId));
    const profileImage = attachments.find(a => a.type === agentImageType.headshot);
    const brokerageLogo = attachments.find(a => a.type === agentImageType.brokerageLogo);

    commit('setAgent', basicInfo);
    commit('setAssignedClients', assignedClients);
    commit('setAgentProfileImage', profileImage);
    commit('setBrokerageLogo', brokerageLogo);
    commit('setLoading', { type: 'details', status: false });
    return basicInfo;
  },
  async getAgentAsa({ commit, state }) {
    const agentId = state.agent.id;
    if (!agentId) return commit('setAsa', null);

    commit('setLoading', { type: 'asa', status: true });

    const { data } = await api.get(paths.AGENT_ASA(agentId));
    commit('setAsa', data);

    commit('setLoading', { type: 'asa', status: false });
  },
  async getAgentSummary({ state, commit }) {
    commit('setLoading', { type: 'dashboard', status: true });

    const agentId = state.agent.id;
    const { data } = await api.get(paths.DASHBOARD_SUMMARY(agentId));
    commit('setSummary', data);

    commit('setLoading', { type: 'dashboard', status: false });
  },
  async getSecondaryAgents({ state, commit }) {
    const { data } = await api.get(paths.SECONDARY_AGENTS(state.agent.id));
    const agents = data.map(a => ({
      ...a,
      fullName: `${a.firstName} ${a.lastName}`,
    }));
    commit('setSecondaryAgents', agents);
  },
  async getSecondaryAgentSummary({ state, commit }, secondaryAgentId) {
    commit('setLoading', { type: 'dashboard', status: true });

    const secondaryAgent = state.secondaryAgents.find(a => secondaryAgentId === a.id);
    commit('setSecondaryAgent', secondaryAgent);
    const { data } = await api.get(paths.DASHBOARD_SUMMARY(secondaryAgentId));
    commit('setSummary', data);

    commit('setLoading', { type: 'dashboard', status: false });
  },
  async resetSecondaryAgent({ commit }) {
    commit('setSecondaryAgent', null);
  },
  async checkEmailToken(context, code) {
    await api.post(`${paths.EMAIL_UPDATE_CONFIMATION}/${code}`);
  },
  async phoneGenerateCode(context, payload) {
    await api.post(paths.PHONE_UPDATE, payload);
  },
  async validateCode(context, payload) {
    const { data } = await api.post(paths.PHONE_UPDATE_CONFIRMATION, payload);
    return data;
  },
  async getUserTours({ commit }, userId) {
    const { data } = await api.get(paths.GET_USER_TOURS(userId));
    commit('setTours', data);
    return data;
  },
  async deactivateTour(context, { userId, tourId }) {
    const { data } = await api.put(paths.DEACTIVATE_TOUR_BY_ID(userId, tourId));
    return data;
  },
  async assignSecondaryAgent(context, { referralId, secondaryAgentId, profileType }) {
    if (profileType === 'Property') {
      await api.post(paths.PROPERTY_REFERRAL_SECONDARY_AGENT(referralId, secondaryAgentId));
    }
    if (profileType === 'Buyer') {
      await api.post(paths.BUYER_PROFILE_REFERRAL_SECONDARY_AGENT(referralId, secondaryAgentId));
    }
  },
  setLoading({ commit }, payload) {
    commit('setLoading', payload);
  },
  async uploadAgentAttachment({ state, commit }, { formData, fileType }) {
    const agentId = state.agent.id;
    const options = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };
    const pathUrl = paths.UPDATE_AGENT_ATTACHMENT(agentId, fileType);
    const { data } = await api.post(pathUrl, formData, options);
    if (fileType === agentImageType.headshot) {
      commit('setAgentProfileImage', data);
    } else if (fileType === agentImageType.brokerageLogo) {
      commit('setBrokerageLogo', data);
    }
    return data;
  },
  async removeAgentAttachment({ state, commit }, attachment) {
    const reqBody = { ids: [attachment.id] };
    const reqOptions = { data: reqBody };
    const agentId = state.agent.id;
    const { data } = await api.delete(paths.AGENT_ATTACHMENT(agentId), reqOptions);
    if (attachment.type === agentImageType.headshot) {
      commit('setAgentProfileImage', null);
    } else if (attachment.type === agentImageType.brokerageLogo) {
      commit('setBrokerageLogo', null);
    }
    return data;
  },
  async fetchProfileListingsOnDemand({ state, commit }) {
    const { data } = await api.get(paths.LISTINGS_ON_DEMAND(state.agent.id));
    commit('setListingsOnDemand', { data });
  },
};

const mutations = {
  setAgent(state, agent) {
    state.agent = agent;
  },
  setSummary(state, summary) {
    state.summary = summary;
  },
  setAsa(state, asa) {
    state.asa = asa;
  },
  setSecondaryAgent(state, secondaryAgent) {
    state.secondaryAgent = secondaryAgent;
  },
  setSecondaryAgents(state, secondaryAgents) {
    state.secondaryAgents = secondaryAgents;
  },
  setTours(state, tours) {
    state.tours = tours;
  },
  setUser(state, user) {
    state.user = user;
  },
  setAssignedClients(state, assignedClients) {
    state.assignedClients = assignedClients;
  },
  setAdmin(state, admin) {
    state.admin = admin;
  },
  setLoading(state, { type, status }) {
    Vue.set(state.loading, type, status);
  },
  setListingsOnDemand(state, listingsOnDemand) {
    state.listingsOnDemand = listingsOnDemand;
  },
  setAgentProfileImage(state, image) {
    Vue.set(state.agent, 'profileImage', image);
  },
  setBrokerageLogo(state, image) {
    Vue.set(state.agent, 'brokerageLogo', image);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
