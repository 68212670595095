/* eslint-disable max-len */
import Vue from 'vue';
import VueRouter from 'vue-router';
import { MAINTENANCE_MODE } from '@/constants';
import { getUserToken, getAdminToken } from '@/utils/localStorageControls';
import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.meta.requiresAuth || to.matched.some((record) => record.meta.requiresAuth);
  const requiresAdmin = to.meta.requiresAdmin || to.matched.some((record) => record.meta.requiresAdmin);
  const isAdmin = getAdminToken();
  const loggedIn = getUserToken() || isAdmin;
  const userNotSelected = isAdmin && !getUserToken();

  if (MAINTENANCE_MODE) {
    if (to.path === '/maintenance') next();
    else next('/maintenance');
    return;
  }

  // if the user is already logged in
  if (!requiresAuth && loggedIn) {
    next('/');
    return;
  }
  // if non-logged in user tries to access routes not meant for him
  if (requiresAuth && !loggedIn) {
    next({
      path: '/login',
      query: {
        redirect: to.fullPath,
        requiresAdmin,
      },
    });
    return;
  }
  // if non-admin tries to access admin pages
  if (requiresAdmin && !isAdmin) {
    next('/');
    return;
  }
  // if admin did not select a user to impersonate
  if (requiresAuth && !requiresAdmin && userNotSelected) {
    next({
      path: '/user-impersonation',
      query: {
        redirect: to.fullPath,
        requiresAdmin,
      },
    });
    return;
  }
  next();
});

export default router;
